import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { colors, screens } from "../../../utils";
import AllSchools from "./AllSchools";
import { Button } from "../../FormElements";
import { MdOutlineCloudDownload, MdOutlineCloudUpload } from "react-icons/md";
import Modal from "../../Modal";
import UploadCurriculum from "./UploadCurriculum";
import CurriculumList from "./CurriculumList";
import TabsWrapper from "../../common/TabsWrapper";
import { useGetAllSchoolsQuery } from "../../../app/services/adminApi";

const tabItems = ["All Schools", "Curriculums"];

const AllSchoolsScreen = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(() => {
    const storedTab = sessionStorage.getItem("activeTab");
    return storedTab || tabItems[0];
  });
  const { data, isError, isSuccess, isLoading, error } = useGetAllSchoolsQuery(
    {}
  );

  console.log(data);

  useEffect(() => {
    sessionStorage.setItem("activeTab", tab);
  }, [tab]);

  const renderChildren = () => {
    switch (tab) {
      case tabItems[0]:
        return <AllSchools />;
      case tabItems[1]:
        return <CurriculumList />;
      default:
        return <AllSchools />;
    }
  };

  const handleDownloadData = () => {
    if (!data || data.length === 0) {
      alert("No school data available to download.");
      return;
    }

    // Define CSV headers
    const csvHeaders = [
      "Name",
      "Email Address",
      "Phone No",
      "Address",
      "LGA",
      "State",
      "No of Students",
    ];

    // Convert JSON data to CSV format
    const csvRows = data.map((school: any) => [
      `"${school.name || ""}"`,
      `"${school.email || ""}"`,
      `"${school.phoneNo || ""}"`,
      `"${school.address || ""}"`,
      `"${school.lga || ""}"`,
      `"${school.state || ""}"`,
      `"${school.students ? school.students.length : 0}"`,
    ]);

    // Combine headers and rows
    const csvContent = [
      csvHeaders.join(","),
      ...csvRows.map((row: any) => row.join(",")),
    ].join("\n");

    // Create a downloadable Blob
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a download link
    const a = document.createElement("a");
    a.href = url;
    a.download = "school_data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke URL after download
    URL.revokeObjectURL(url);
  };

  const renderNavItems = () => {
    return (
      <HeadingWrapper>
        <TabList className="sub-nav">
          {tabItems.map((item) => (
            <li
              key={item}
              className={item === tab ? "selected" : ""}
              onClick={() => setTab(item)}
            >
              {item}
            </li>
          ))}
        </TabList>
        <ButtonContainer>
          <ButtonWrapper>
            <Button
              text={
                <TextWrapper>
                  <MdOutlineCloudUpload />
                  <p>Upload Curriculum</p>
                </TextWrapper>
              }
              color={colors.white}
              backgroundColor={colors.primary}
              border="none"
              onClick={handleAddCurriculum}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              text={
                <TextWrapper>
                  <MdOutlineCloudDownload />
                  <p>Download School Data</p>
                </TextWrapper>
              }
              color={colors.white}
              backgroundColor={colors.primary}
              border="none"
              onClick={handleDownloadData}
            />
          </ButtonWrapper>
        </ButtonContainer>
      </HeadingWrapper>
    );
  };

  const handleAddCurriculum = () => {
    setIsOpen(true);
  };

  return (
    <Container>
      <Wrapper>
        <ContentWrapper>
          <h1>Manage schools here</h1>

          <TabsWrapper renderNavItems={renderNavItems}>
            <TabsContent>{renderChildren()}</TabsContent>
          </TabsWrapper>
        </ContentWrapper>

        {/* <AllSchools /> */}
      </Wrapper>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <UploadCurriculum closeModal={() => setIsOpen(false)} />
      </Modal>
    </Container>
  );
};

export default AllSchoolsScreen;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 1rem auto;
  flex-direction: column;
  gap: 1rem;
`;

const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${screens.microtab}) {
    flex-direction: column;
    align-items: normal;
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.dark};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: center;
`;

const TabList = styled.ul`
  list-style: none;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;

  @media Screen and (max-width: ${screens.tab}) {
    gap: 1rem;
  }

  li {
    padding: 0.2rem 1rem;
    background-color: ${colors.white};
    border-radius: 0.25rem;
    cursor: pointer;
    color: ${colors.dark};
    font-size: 0.9rem;
    border: 1px solid ${colors.primary};

    @media Screen and (max-width: ${screens.tab}) {
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .selected {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const TabsContent = styled.div`
  display: flex;
  width: 100%;
  //height: 100vh;
  overflow: scroll;
  padding-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  gap: 1rem;
`;
